export function getEnvVarAllowMissing(name: string): string | undefined {
  return process.env[name];
}

export function getEnvVar(name: string): string {
  const value = getEnvVarAllowMissing(name);
  if (!value) {
    throw new Error(`Missing env var: "${name}"`);
  }
  return value;
}
