import React from "react";

import { JiscH2 } from "../Text/JiscH2";
import { JiscBody1 } from "../Text/JiscBody1";

import styles from "./Results.module.css";

interface Props {
  description?: string;
}

export const Results: React.FC<Props> = ({ description, children }) => {
  return (
    <div className={styles.root}>
      <JiscH2>Results</JiscH2>
      {description && <JiscBody1>{description}</JiscBody1>}
      <div className={styles.Results}>{children}</div>
    </div>
  );
};
