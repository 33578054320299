import React, { useContext, useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { InstitutionHeadings } from "../Results/Accordion/InstitutionHeadings";
import { Accordion } from "../Results/Accordion/Accordion";

import { Results } from "./Results";

import { StateContext } from "../../state/StateContextProvider";
import { Agreement, Journal } from "../../state/types";
import { fetchJournals } from "../../network/gql/queries";
import { pluralize } from "../../utils/helpers";
import { agreementSection, journalsSection } from "../../utils/accordion";
import { Spacer } from "../Text/Spacer";
import { contactSection } from "../../utils/contact";

function uniquePublishersCount(agreements: Agreement[]): number {
  const publishers = new Set<string>(agreements.map(a => a.publisher_name));
  return publishers.size;
}

interface InstitutionAgreementProps {
  agreement: Agreement;
}

const JOURNALS_ERR_MESSAGE = "Error retrieving journals. Please try again later.";

/**
 * Accordion holding a single TA in the institution view.
 */
const InstitutionAgreementAccordion: React.FC<InstitutionAgreementProps> = ({
  agreement,
}: InstitutionAgreementProps) => {
  const client = useApolloClient();
  const [error, setError] = useState("");
  const [journals, setJournals] = useState<Journal[]>([]);

  const headings = {
    publisherName: agreement.publisher_name,
    agreementName: agreement.name,
    startDate: agreement.date_start,
    endDate: agreement.date_end,
  };

  const loadJournals = async (): Promise<void> => {
    if (journals.length === 0) {
      try {
        setJournals(await fetchJournals(client, agreement));
      } catch (err: any) {
        setError(JOURNALS_ERR_MESSAGE);
      }
    }
  };

  return (
    <Accordion
      key={agreement.id}
      kind="institution"
      headings={<InstitutionHeadings headings={headings} />}
      onOpenFunc={async (open: boolean) => {
        if (open) {
          await loadJournals();
        }
      }}
    >
      <>
        {agreementSection(agreement)}
        {journalsSection(journals, error)}
        <Spacer size={3} />
        {contactSection(agreement)}
      </>
    </Accordion>
  );
};

/**
 * Defines the entire 'Browse institution' view.
 */
export const InstitutionSection: React.FC = () => {
  const { state } = useContext(StateContext);
  const [description, setDescription] = useState("");

  /**
   * Set results description based on number of agreements.
   */
  useEffect(() => {
    if (state.agreements.length > 0) {
      const uniquePubs = uniquePublishersCount(state.agreements);
      setDescription(
        `Your institution holds agreements with ${uniquePubs} ` +
          `${pluralize("publisher", uniquePubs)}. ` +
          `Click on any publisher to expand and view the covered journals.`
      );
    } else {
      setDescription("No agreements found");
    }
  }, [state.agreements]);

  return (
    <div>
      {state.agreementSearchPerformed && (
        <Results description={description}>
          {state.agreements.map(agreement => (
            <InstitutionAgreementAccordion key={agreement.id} agreement={agreement} />
          ))}
        </Results>
      )}
    </div>
  );
};
