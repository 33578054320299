import React, { useContext, useEffect, useState } from "react";

import {
  JournalHeadings,
  JournalHeadingsProps,
} from "../Results/Accordion/JournalHeadings";
import { Accordion } from "../Results/Accordion/Accordion";
import { AccordionLegend } from "../Results/Accordion/AccordionLegend";
import { Results } from "./Results";
import { Spacer } from "../Text/Spacer";
import { StateContext } from "../../state/StateContextProvider";
import {
  agreementSection,
  agreementSectionFromMap,
  getNoAgreementText,
  JournalAgreementProps,
  makeAgreementToInstitutionsMap,
  makeJournalAccordionProps,
} from "../../utils/accordion";
import { getJournalIdentifiers } from "../../state/types";
import { JiscBody1 } from "../Text/JiscBody1";
import { pluralize } from "../../utils/helpers";
import { contactSection } from "../../utils/contact";

const JournalAgreementAccordion: React.FC<JournalAgreementProps> = ({
  journal,
  agreements,
}) => {
  const { state } = useContext(StateContext);
  const identifiers = getJournalIdentifiers(journal);
  const journalName = journal.title;

  const props: JournalHeadingsProps = {
    headings: {
      hasAgreement: agreements.length ? "Yes" : "No",
      identifiers,
      journalName,
      publisherName: agreements.length ? agreements[0].publisher_name : "N/A",
    },
  };

  if (agreements.length > 0) {
    if (state.selectedInstitution) {
      return (
        <Accordion
          key={`journal-hei-${journal.id}`}
          kind="journal"
          headings={<JournalHeadings headings={props.headings} />}
        >
          <JiscBody1>
            {`This journal is part of the following ${pluralize(
              "agreement",
              agreements.length
            )} with ${state.selectedInstitution.name}:`}
          </JiscBody1>
          {agreements.map(agreement => {
            return (
              <React.Fragment key={`ag-${agreement.id}`}>
                <Spacer size={3} />
                {agreementSection(agreement)}
                {contactSection(agreement)}
              </React.Fragment>
            );
          })}
        </Accordion>
      );
    }

    const agreementsMap = makeAgreementToInstitutionsMap(agreements);

    return (
      <Accordion
        key={`journal-no-hei-${journal.id}`}
        kind="journal"
        headings={<JournalHeadings headings={props.headings} />}
      >
        <JiscBody1>
          {`This journal is part of the following ${pluralize(
            "agreement",
            agreementsMap.size
          )}`}
          :
        </JiscBody1>
        {agreementSectionFromMap(agreementsMap)}
      </Accordion>
    );
  }

  return (
    <>
      <Accordion
        key={`journal-noag-${journal.id}`}
        kind="journal"
        headings={<JournalHeadings headings={props.headings} />}
      >
        <JiscBody1 component="p">{getNoAgreementText(state, "journal")}</JiscBody1>
      </Accordion>
    </>
  );
};

export const JournalSection: React.FC = () => {
  const { state } = useContext(StateContext);
  const [accordionProps, setAccordionProps] = useState<JournalAgreementProps[]>([]);

  useEffect(() => {
    if (state.agreementSearchPerformed) {
      setAccordionProps(makeJournalAccordionProps(state));
    }
  }, [state]);

  const makeResultsSection = (): JSX.Element => {
    return (
      <Results description="Click on any journal to expand and view agreement details.">
        <AccordionLegend resultType="journal" />
        {accordionProps.map(({ journal, agreements }) => (
          <JournalAgreementAccordion
            key={`journal-acc-${journal.id}`}
            agreements={agreements}
            journal={journal}
          />
        ))}
      </Results>
    );
  };

  return <div>{state.agreementSearchPerformed && makeResultsSection()}</div>;
};
