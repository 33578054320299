import React from "react";

import { Typography } from "@material-ui/core";

import styles from "./JiscH2.module.css";

export const JiscH2: React.FC = ({ children }) => {
  return (
    <Typography variant="h2" gutterBottom className={styles.root}>
      {children}
    </Typography>
  );
};
