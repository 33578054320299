import React from "react";

import { JiscH3 } from "../../Text/JiscH3";

import styles from "./Accordion.module.css";

type ResultType = "institution" | "journal" | "publisher";

interface Props {
  resultType: ResultType;
}

export const AccordionLegend: React.FC<Props> = ({ resultType }) => {
  function switchResultType(type: ResultType) {
    switch (type) {
      case "institution":
        return <></>;
      case "publisher":
        return (
          <div
            className={`${styles.AccordionHeadingContainer} ${styles.publisherGridColumns}}`}
          >
            <div className={styles.AccordionHeading}>
              <JiscH3>Publisher &amp; Institution</JiscH3>
            </div>
            <div className={styles.AccordionHeading}>
              <JiscH3>Jisc Agreement held?</JiscH3>
            </div>
          </div>
        );
      case "journal":
        return (
          <div
            className={`${styles.AccordionHeadingContainer} ${styles.journalGridColumns}`}
          >
            <div className={styles.AccordionHeading}>
              <JiscH3>Journal</JiscH3>
            </div>
            <div className={styles.AccordionHeading}>
              <JiscH3>ISSN / eISSN</JiscH3>
            </div>
            <div className={styles.AccordionHeading}>
              <JiscH3>Publisher</JiscH3>
            </div>
            <div className={styles.AccordionHeading}>
              <JiscH3>Jisc Agreement held?</JiscH3>
            </div>
          </div>
        );
      default:
        return <div>Unexpected result type</div>;
    }
  }

  return <div className={styles.AccordionLegend}>{switchResultType(resultType)}</div>;
};
