/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren, ReactElement, useState } from "react";
import Highlighter from "react-highlight-words";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import { Institution, Journal, Publisher } from "../../../state/types";
import styles from "./QueryRunnerSelector.module.css";

type Option = Institution | Publisher | Journal;

interface Props<T> {
  id: string;
  placeholder: string;
  loadOptionsFunc: LoadOptions<any, any>;
  saveSelectionFunc: (selection: T) => void;
  error: string;
  openMenuOnClick: boolean;
  enabled?: boolean;
  textForDisabledInput?: string;
}

export const QueryRunnerSelector = <T,>({
  id,
  placeholder,
  loadOptionsFunc,
  saveSelectionFunc,
  error,
  openMenuOnClick,
  enabled,
  textForDisabledInput,
}: PropsWithChildren<Props<T>>): ReactElement<any, any> | null => {
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState<T | null>(null);
  const [clearCache, setClearCache] = useState(0);

  const IndicatorsContainer: React.FC = () => {
    return null;
  };

  const formatOptionLabel = (option: Option) => {
    return (
      <Highlighter
        autoEscape
        searchWords={inputValue.split(" ")}
        textToHighlight={option ? option.label : ""}
      />
    );
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
    if (newValue === "") {
      setSelected(null);
    }
  };

  const handleChange = (newValue: T | null) => {
    if (newValue !== null) {
      setSelected({ ...newValue });
      saveSelectionFunc(newValue);
      setInputValue("");
    } else {
      setSelected(null);
    }
  };

  const noOptions = () => {
    if (error) {
      return error;
    }
    return `No options for input: "${inputValue}"`;
  };

  return (
    <div>
      <AsyncPaginate
        isDisabled={!enabled}
        escapeClearsValue
        controlShouldRenderValue={false}
        className={styles.Autocomplete}
        components={{ IndicatorsContainer }}
        classNamePrefix="react-select-autocomplete"
        id={id}
        inputValue={inputValue}
        placeholder={enabled ? placeholder : textForDisabledInput}
        formatOptionLabel={formatOptionLabel}
        onInputChange={handleInputChange}
        onChange={handleChange}
        noOptionsMessage={noOptions}
        openMenuOnClick={openMenuOnClick}
        value={selected}
        loadOptions={loadOptionsFunc}
        debounceTimeout={200}
        loadingMessage={obj => (obj.inputValue === "" ? null : "Loading...")}
        cacheOptions={false}
        cacheUniqs={[inputValue, clearCache]}
        onMenuOpen={() => {
          // This appears to be necessary to stop caching. Caching is a problem if
          // there is a temporary network issue - repeating the same query doesn't work!
          // See https://github.com/vtaits/react-select-async-paginate/issues/63#issuecomment-825388540
          setClearCache(clearCache + 1);
        }}
        aria-label={`${id}-label`}
      />
    </div>
  );
};
