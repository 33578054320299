import React from "react";

import { JiscBody1 } from "../Text/JiscBody1";
import { Spacer } from "../Text/Spacer";

import styles from "./ListItem.module.css";

interface Props {
  item: { value: string; label: string };
  deleteItem: (id: string) => void;
  bold?: boolean;
}

export const ListItem: React.FC<Props> = ({ item, deleteItem, bold }) => {
  const handleKeyUp = (e: React.KeyboardEvent<HTMLSpanElement>, id: string) => {
    if (e.key === "Enter" || e.key === "Space" || e.key === " ") {
      return deleteItem(id);
    }
    return null;
  };

  return (
    <li key={`${item.value}-journalListItem`}>
      <span>
        <JiscBody1 bold={bold}>{item.label}</JiscBody1>
      </span>
      <span
        className={styles.ListItemDeleteButton}
        onKeyUp={e => handleKeyUp(e, item.value)}
        role="button"
        tabIndex={0}
        aria-label="Remove"
        onClick={() => deleteItem(item.value)}
      >
        <JiscBody1>Remove</JiscBody1>
      </span>
      <Spacer />
    </li>
  );
};
