import React, { useContext, useState } from "react";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import { LoadOptions, Response } from "react-select-async-paginate";
import { ListItem } from "../../ListItem/ListItem";
import { JiscH2 } from "../../Text/JiscH2";
import styles from "../Form.module.css";
import { SEARCH_INSTITUTIONS_QUERY } from "../../../network/gql/queries";
import { QueryRunnerSelector } from "../QueryRunnerSelector/QueryRunnerSelector";
import { StateContext } from "../../../state/StateContextProvider";
import { Institution } from "../../../state/types";
import { ActionType } from "../../../utils/enums";
import { SearchInstitutionsResult } from "../../../network/gql/types";

const INST_PLACEHOLDER = "Enter institution name";

/**
 * Search for one (1) institution to use in transitional agreements searches.
 */
export const InstitutionForm: React.FC = () => {
  const client = useApolloClient();
  const { state, dispatch } = useContext(StateContext);
  const selectedInstitution = state.selectedInstitution;
  const [error, setError] = useState("");
  const [placeholder, setPlaceholder] = useState(INST_PLACEHOLDER);

  const transformer = (institution: Institution) => {
    return {
      name: institution.name,
      joid: institution.joid,
      label: institution.name,
      value: institution.joid,
    };
  };

  const loadOptions: LoadOptions<Institution, unknown> = async (
    searchQuery: string
  ): Promise<Response<Institution, unknown>> => {
    let response: ApolloQueryResult<SearchInstitutionsResult>;
    const query = searchQuery.trim();

    try {
      response = await client.query<SearchInstitutionsResult>({
        query: SEARCH_INSTITUTIONS_QUERY,
        variables: { query },
      });
    } catch (err: any) {
      setError(err.message);
      return {
        options: [],
        hasMore: false,
      };
    }

    const results: readonly Institution[] = response.data.searchInstitutions.results.map(
      (i: any) => transformer(i)
    );

    return {
      options: results,
      hasMore: false,
    };
  };

  /**
   * Update the institution choice globally.
   */
  const setInstitution = (selection: Institution | null) => {
    dispatch({
      type: ActionType.SetInstitution,
      payload: selection,
    });
    if (selection === null) {
      dispatch({
        type: ActionType.ClearAllAgreements,
      });
      setPlaceholder(INST_PLACEHOLDER);
    } else {
      setPlaceholder("Selected");
    }
  };

  return (
    <div>
      <JiscH2>Select your institution</JiscH2>
      <div className={styles.Form}>
        <QueryRunnerSelector<Institution>
          id="institution-select-form"
          placeholder={placeholder}
          loadOptionsFunc={loadOptions}
          saveSelectionFunc={setInstitution}
          enabled
          error={error}
          openMenuOnClick
        />
      </div>
      {selectedInstitution && (
        <div>
          <ul className={styles.ListContainer}>
            <ListItem
              key={selectedInstitution.label}
              item={selectedInstitution}
              deleteItem={() => setInstitution(null)}
              bold
            />
          </ul>
        </div>
      )}
    </div>
  );
};
