export function pluralize(thing: string, count: number, es = false): string {
  if (count < 2) {
    return thing;
  }
  if (es) {
    return `${thing}es`;
  }
  return `${thing}s`;
}

export function numberWithCommas(n: number): string {
  // ...because Number.prototype.toLocaleString doesn't work in Safari in 2021
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
