import React from "react";

import { Typography } from "@material-ui/core";

import styles from "./JiscBody1.module.css";

interface Props {
  withGutter?: boolean;
  inline?: boolean;
  component?: "span" | "p";
  bold?: boolean;
  variant?: string;
  onClick?: () => void;
}

export const JiscBody1: React.FC<Props> = ({
  withGutter = true,
  children,
  inline = false,
  component = "span",
  bold = false,
  onClick,
}) => {
  const isInline = inline ? styles.inline : "";
  const isBold = bold ? styles.bold : "";

  return (
    <Typography
      variant="body1"
      gutterBottom={withGutter}
      className={`${styles.body1} ${isInline} ${isBold}`}
      component={component}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};
