import React, { createContext, useReducer } from "react";

import { reducer, initialState, State, Action } from "./reducer";

interface StateContextInterface {
  state: State;
  dispatch: React.Dispatch<Action>;
}

export const StateContext = createContext<StateContextInterface>({
  state: { ...initialState },
  dispatch: () => {},
});

export const StateContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={{ state, dispatch }}>{children}</StateContext.Provider>
  );
};
