import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ReactDOM from "react-dom";
import axe from "@axe-core/react";
import { StylesProvider } from "@material-ui/core/styles";

import { ApolloProvider } from "@apollo/client";
import { getApolloClient } from "./network/gql/client";

import { StateContextProvider } from "./state/StateContextProvider";
import { Title } from "./components/Sections/Title";
import { SearchSection } from "./components/Sections/SearchSection";
import { ResultsPage } from "./components/Sections/ResultsPage";
import "fontsource-roboto/latin.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Logo from "./jisc-logo.svg";
import styles from "./App.module.css";
import "./styles/global.css";

if (process.env.NODE_ENV !== "production") {
  axe(React, ReactDOM, 500);
}

const App: React.FC = () => {
  return (
    <ApolloProvider client={getApolloClient()}>
      <StylesProvider injectFirst>
        <StateContextProvider>
          <div className={styles.App}>
            <header className={styles["App-header"]} role="banner">
              <a title="Jisc" rel="noreferrer" href="https://jisc.ac.uk">
                <img src={Logo} alt="Jisc" className={styles.JiscLogo} />
              </a>
            </header>
            <main className={styles.AppBody} role="main">
              <div className={styles.Title}>
                <Title />
              </div>
              <div>
                <Router>
                  <Routes>
                    <Route path="/" element={<SearchSection />} />
                    <Route path="/results" element={<ResultsPage />} />
                  </Routes>
                </Router>
              </div>
            </main>
          </div>
        </StateContextProvider>
      </StylesProvider>
    </ApolloProvider>
  );
};

export default App;
