export enum ActionType {
  SetJournals = "SET_JOURNALS",
  SetPublishers = "SET_PUBLISHERS",
  SetInstitution = "SET_INSTITUTION",
  ToggleNoInstitution = "TOGGLE_NO_INSTITUTION",
  SetAgreementSearchPerformed = "SET_AGREEMENT_SEARCH_PERFORMED",
  SetAgreements = "SET_AGREEMENTS",
  SetJournalAgreements = "SET_JOURNAL_AGREEMENTS",
  ClearAllAgreements = "CLEAR_ALL_AGREEMENTS",
}
