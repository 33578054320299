import React from "react";

import { JiscBody1 } from "../../Text/JiscBody1";

import styles from "./Accordion.module.css";

export interface JournalHeadingsProps {
  headings: {
    journalName: string;
    identifiers: string;
    publisherName?: string;
    hasAgreement: string;
  };
}

export const JournalHeadings: React.FC<JournalHeadingsProps> = ({ headings }) => {
  return (
    <>
      <div className={`${styles.AccordionHeading} ${styles.Col1}`}>
        <JiscBody1 withGutter={false}>{headings.journalName}</JiscBody1>
      </div>
      <div className={`${styles.AccordionHeading} ${styles.Col2}`}>
        <JiscBody1 withGutter={false}>{headings.identifiers}</JiscBody1>
      </div>
      {headings.publisherName && (
        <div className={`${styles.AccordionHeading} ${styles.Col3}`}>
          <JiscBody1 withGutter={false}>{headings.publisherName}</JiscBody1>
        </div>
      )}
      <div className={`${styles.AccordionHeading} ${styles.Col4}`}>
        <JiscBody1 withGutter={false}>{headings.hasAgreement}</JiscBody1>
      </div>
    </>
  );
};
