import React from "react";
import { List, ListItem, Paper } from "@material-ui/core";
import { JiscBody1 } from "../Text/JiscBody1";

interface Props {
  items: { id: string; label: string }[];
}

export const ScrollableList: React.FC<Props> = ({ items }) => {
  return (
    <Paper elevation={3} style={{ maxHeight: 470, overflow: "auto" }}>
      <List>
        {items.map(i => {
          return (
            <ListItem key={i.id}>
              <JiscBody1 withGutter={false}>— {i.label}</JiscBody1>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};
