import React from "react";

import { Typography } from "@material-ui/core";

import styles from "./JiscH3.module.css";

export const JiscH3: React.FC = ({ children }) => {
  return (
    <Typography variant="h3" className={styles.h3}>
      {children}
    </Typography>
  );
};
