import React from "react";
import { LinearProgress } from "@material-ui/core";
import { ScrollableList } from "../components/ScrollableList/ScrollableList";
import { JiscBody1 } from "../components/Text/JiscBody1";
import { Spacer } from "../components/Text/Spacer";
import { Agreement, Journal } from "../state/types";
import { numberWithCommas } from "./helpers";
import { State } from "../state/reducer";

/**
 * Return a summary of an LSM agreement.
 */
export function agreementSection(agreement: Agreement): JSX.Element {
  return (
    <>
      <JiscBody1 component="p">Agreement name: {agreement.name}</JiscBody1>
      <JiscBody1 component="p">
        Agreement URL: <a href={agreement.url}>{agreement.url}</a>
      </JiscBody1>
      <JiscBody1 component="p">Start date: {agreement.date_start}</JiscBody1>
      <JiscBody1 component="p">End date: {agreement.date_end}</JiscBody1>
    </>
  );
}

/**
 * Return a summary of the agreements and institutions of a
 * Map<ProductId, AgreementToInstitutions> with the institutions inside a
 * ScrollableList.
 */
export function agreementSectionFromMap(
  agreementsMap: Map<ProductId, AgreementToInstitutions>
): JSX.Element[] {
  return Array.from(agreementsMap.entries()).map(([productId, details]) => {
    return (
      <React.Fragment key={`ag-${productId}`}>
        <Spacer size={3} />
        <JiscBody1 component="p">Agreement name: {details.agreementName}</JiscBody1>
        <JiscBody1 component="p">
          Agreement URL: <a href={details.url}>{details.url}</a>
        </JiscBody1>
        <Spacer size={2} />
        <JiscBody1 component="p">
          The following institutions hold this agreement between the following dates:
        </JiscBody1>
        <Spacer size={2} />
        <ScrollableList items={details.institutions} />
      </React.Fragment>
    );
  });
}

/**
 * Return the 'journals' section of an LSM agreement accordion.
 */
export function journalsSection(journals: Journal[], error: string): JSX.Element {
  if (error) {
    return <JiscBody1>{error}</JiscBody1>;
  }
  if (journals.length > 0) {
    return (
      <>
        <JiscBody1>
          The following {numberWithCommas(journals.length)} journals are included in this
          agreement:
        </JiscBody1>
        <Spacer size={2} />
        <ScrollableList items={journals} />
      </>
    );
  }
  return (
    <>
      <JiscBody1>Fetching journals...</JiscBody1>
      <LinearProgress />
    </>
  );
}

export interface PublisherAgreementProps {
  agreement?: Agreement;
  publisherName: string;
  publisherJoid: string;
}

/**
 * Return a copy of the Agreements sorted by institution_name.
 */
export function sortAgreementsByInstitution(agreements: Agreement[]): Agreement[] {
  return [...agreements].sort((agA, agB) => {
    if (agB.institution_name < agA.institution_name) {
      return 1;
    }
    return 0;
  });
}

export interface JournalAgreementProps {
  journal: Journal;
  agreements: Agreement[];
}

/**
 * Return props to make all the JournalAgreementAccordion using the
 * state's selectedJournals and journalAgreements.
 */
export function makeJournalAccordionProps(state: State): JournalAgreementProps[] {
  const props: JournalAgreementProps[] = [];
  Object.entries(state.journalAgreements).forEach(([publicationId, agreements]) => {
    const journal = state.selectedJournals.filter(j => j.id === publicationId)[0];
    props.push({ journal, agreements });
  });

  return props;
}

/**
 * Return text to display for a publisher or journal Accordion that has no
 * associated agreements.
 */
export function getNoAgreementText(state: State, kind: "publisher" | "journal"): string {
  let suffix: string;

  if (state.selectedInstitution) {
    suffix = ` with ${state.selectedInstitution.name}.`;
  } else {
    suffix = ".";
  }

  if (kind === "journal") {
    return `This journal is not part of a Jisc negotiated transitional agreement${suffix}`;
  }

  return `This publisher does not have a Jisc negotiated transitional agreement${suffix}`;
}

/**
 * Simplified Institution for use as a list item.
 */
export interface InstitutionItem {
  id: string;
  name: string;
  label: string;
}

type ProductId = string;

/**
 * Type for holding basic info about an agreement and the institutions that
 * subscribe to it. Only includes enough fields for display in an Accordion.
 *
 * For use in a Map<ProductId, AgreementToInstitutions>.
 *
 * This is only useful when searching for agreements by journal, when an
 * institution has not been selected, and at least one agreement has been found.
 */
export interface AgreementToInstitutions {
  agreementName: string;
  url: string;
  institutions: InstitutionItem[];
}

/**
 * Make an AgreementToInstitutionsMap for use in JournalAgreementAccordions for when
 * no institution is selected, and multiple Agreements have been found that include
 * a journal.
 *
 * @param agreements All the agreements found that include a journal
 */
export function makeAgreementToInstitutionsMap(
  agreements: Agreement[]
): Map<ProductId, AgreementToInstitutions> {
  const agMap = new Map<ProductId, AgreementToInstitutions>();

  agreements.forEach(ag => {
    const maybeEntry = agMap.get(ag.product_id);
    let entry: AgreementToInstitutions;

    if (!maybeEntry) {
      entry = { agreementName: ag.name, url: ag.url, institutions: [] };
      agMap.set(ag.product_id, entry);
    } else {
      entry = maybeEntry;
    }

    const joid = ag.institution_joid;
    const id = `${joid}-${ag.id}`;
    entry.institutions.push({
      id,
      name: ag.institution_name,
      label: `${ag.institution_name} (${ag.date_start} — ${ag.date_end})`,
    });
  });

  // Sort each list of institutions by name to make it easier to see them.
  agMap.forEach(agToInst => {
    agToInst.institutions.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  });

  return agMap;
}
