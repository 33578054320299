import React from "react";

import { JiscBody1 } from "../../Text/JiscBody1";
import styles from "./Accordion.module.css";

interface Props {
  headings: {
    publisherName: string;
    agreementName: string;
    startDate: string;
    endDate: string;
  };
}

export const InstitutionHeadings: React.FC<Props> = ({ headings }) => {
  return (
    <div className={`${styles.AccordionHeading} ${styles.Col1}`}>
      <JiscBody1 withGutter={false} bold>
        {headings.publisherName}
      </JiscBody1>
      <br />
      <JiscBody1>{headings.agreementName}</JiscBody1>
      <br />
      <JiscBody1>Valid from </JiscBody1>
      <JiscBody1 bold>{headings.startDate}</JiscBody1>
      <JiscBody1> to </JiscBody1>
      <JiscBody1 bold>{headings.endDate}</JiscBody1>
    </div>
  );
};
