import { ApolloClient, ApolloQueryResult, gql } from "@apollo/client";
import { State } from "../../state/reducer";
import { Agreement, Journal, transformJournal } from "../../state/types";
import { GetAgreementPublicationsResult } from "./types";

export const SEARCH_INSTITUTIONS_QUERY = gql(
  `query ($query: String!) {
    searchInstitutions(query: $query) {
      results {
        name,
        joid
      }
    }
  }`
);

export const SEARCH_LSM_PUBLISHERS_QUERY = gql(
  `query ($query: String! $size: Int!) {
    searchLsmPublishers(searchString: $query, size: $size) {
      publishers {
        joid
        name
      }
    }
  }`
);

export const SEARCH_KBP_PUBLICATIONS_QUERY = gql(
  `query ($query: String! $size: Int!) {
    searchKbpPublications(searchString: $query, size: $size) {
      publications {
        id
        title
        issn
        eissn
      }
    }
  }`
);

export const GET_AGREEMENTS_QUERY = gql(
  `query (
    $date_end_gte: SimpleIsoDate!
    $institution_joid: String
    $publisher_joids: [String!]
    $size: Int
    $from: Int
    ) {
    getAgreements(
      date_end_gte: $date_end_gte # "2022-01-05"
      institution_joid: $institution_joid
      publisher_joids: $publisher_joids
      size: $size
      from: $from
    ) {
      agreements {
        id
        name
        institution_joid
        institution_name
        publisher_joid
        publisher_name
        product_id
        product_content_options {
          id
          name
        }
        date_start
        date_end
      }
    }
  }`
);

const DEFAULT_AGREEMENTS_SIZE = 50;

/**
 * Construct the `variables` to send with the GET_AGREEMENTS_QUERY, based on the
 * app state.
 */
export function constructGetAgreementsVariables(
  state: State,
  from: number
): Record<string, unknown> {
  return {
    date_end_gte: new Date().toISOString(),
    institution_joid: state.selectedInstitution ? state.selectedInstitution.joid : null,
    publisher_joids: null,
    size: DEFAULT_AGREEMENTS_SIZE,
    from,
  };
}

export const GET_AGREEMENTS_BY_PUBLICATIONS_QUERY = gql(
  `query(
    $publication_ids: [String!]!
    $date_end_gte: SimpleIsoDate!
    $institution_joid: String
    $size: Int
    $from: Int
  ){
    getAgreementsByPublications(
      publication_ids: $publication_ids
      date_end_gte: $date_end_gte
      institution_joid: $institution_joid
      size: $size
      from: $from
    ) {
      agreementsMap
    }
  }`
);

/**
 * Construct the `variables` to send with the
 * GET_AGREEMENTS_BY_PUBLICATIONS_QUERY, based on the app State.
 */
export function constructGetAgreementsByPublicationsVariables(
  state: State,
  from: number
): Record<string, unknown> {
  return {
    date_end_gte: new Date().toISOString(),
    publication_ids: state.selectedJournals.map(journal => journal.id),
    institution_joid: state.selectedInstitution ? state.selectedInstitution.joid : null,
    size: DEFAULT_AGREEMENTS_SIZE,
    from,
  };
}

export const GET_AGREEMENT_PUBLICATIONS = gql(
  `query($agreementId: String!) {
    getAgreementPublications(
     agreementId: $agreementId
   ) {
    publications {
      id
      title
      issn
      eissn
    }
   }
  }`
);

/**
 * Fetch and return the journals included in this agreement.
 */
export async function fetchJournals(
  client: ApolloClient<any>,
  agreement: Agreement
): Promise<Journal[]> {
  const response: ApolloQueryResult<GetAgreementPublicationsResult> =
    await client.query<GetAgreementPublicationsResult>({
      query: GET_AGREEMENT_PUBLICATIONS,
      variables: { agreementId: agreement.id },
    });
  return response.data.getAgreementPublications.publications.map(j =>
    transformJournal(j)
  );
}
