import { ActionType } from "../utils/enums";

import { Agreement, Institution, Journal, IdToAgreementsMap, Publisher } from "./types";

export interface State {
  selectedJournals: Journal[];
  selectedInstitution: Institution | null;
  // Whether or not a search has been performed with the selected criteria.
  agreementSearchPerformed: boolean;

  // List of agreements found when searching via institution and/or
  // publisher(s)
  agreements: Agreement[];

  // Map of agreements found when searching via journal, because we have
  // to keep track of which journal each agreement relates to.
  journalAgreements: IdToAgreementsMap;
}

export type Action =
  | {
      type: ActionType.SetJournals;
      payload: Journal[];
    }
  | {
      type: ActionType.SetPublishers;
      payload: Publisher[];
    }
  | {
      type: ActionType.SetInstitution;
      payload: Institution | null;
    }
  | {
      type: ActionType.ToggleNoInstitution;
    }
  | {
      type: ActionType.SetAgreements;
      payload: Agreement[];
    }
  | {
      type: ActionType.SetJournalAgreements;
      payload: IdToAgreementsMap;
    }
  | {
      type: ActionType.SetAgreementSearchPerformed;
      payload: boolean;
    }
  | {
      type: ActionType.ClearAllAgreements;
    };

export interface Dispatch {
  (value: Action): void;
}

export const initialState: State = {
  selectedJournals: [],
  selectedInstitution: null,
  agreementSearchPerformed: false,
  agreements: [],
  journalAgreements: {},
};

export function reducer(prevState: State, action: Action): State {
  switch (action.type) {
    case ActionType.SetJournals: {
      const newState = {
        ...prevState,
        selectedJournals: action.payload,
      };
      return newState;
    }
    case ActionType.SetPublishers: {
      const newState = {
        ...prevState,
        selectedPublishers: action.payload,
      };
      return newState;
    }
    case ActionType.SetInstitution: {
      const newState = {
        ...prevState,
        selectedInstitution: action.payload,
      };
      return newState;
    }
    case ActionType.SetAgreementSearchPerformed: {
      const newState = {
        ...prevState,
        agreementSearchPerformed: action.payload,
      };
      return newState;
    }
    case ActionType.SetAgreements: {
      const newState = {
        ...prevState,
        agreements: action.payload,
      };
      return newState;
    }
    case ActionType.SetJournalAgreements: {
      const newState = {
        ...prevState,
        journalAgreements: action.payload,
      };
      return newState;
    }
    case ActionType.ClearAllAgreements: {
      const newState = {
        ...prevState,
        agreements: [],
        journalAgreements: {},
        agreementSearchPerformed: false,
      };
      return newState;
    }
    default:
      throw new Error(`Unhandled action type: ${JSON.stringify(action, null, 2)}`);
  }
}
