import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { StateContext } from "../../state/StateContextProvider";
import { InstitutionSection } from "./InstitutionSection";
import { JournalSection } from "./JournalSection";
import { JiscBody1 } from "../Text/JiscBody1";
import { JiscH2 } from "../Text/JiscH2";
import { Spacer } from "../Text/Spacer";
import { CONTACT_EMAIL, EMAIL_SUBJECT_PREFIX } from "../../utils/contact";

/**
 * Choose what to display from the state depending on which search
 * was used. If the user didn't come here from the search page then
 * the state will be empty, so state 'no results'.
 */
const ResultsContent: React.FC = () => {
  const { state } = useContext(StateContext);

  const gotResults =
    state.agreements.length > 0 || Object.entries(state.journalAgreements).length > 0;

  if (!gotResults) {
    return (
      <>
        <Spacer size={3} />
        <JiscH2>No Results</JiscH2>
      </>
    );
  }
  if (state.agreements.length) {
    return <InstitutionSection />;
  }
  return <JournalSection />;
};

const ResultsPageContact: React.FC = () => {
  const mailto = `mailto:${CONTACT_EMAIL}?subject=${EMAIL_SUBJECT_PREFIX}`;
  return (
    <div>
      <JiscBody1 component="p">
        <a href={mailto}>Click here to email us about these results</a>
      </JiscBody1>
      <JiscBody1>Alternatively, email </JiscBody1>
      <JiscBody1 bold>{CONTACT_EMAIL}</JiscBody1>
      <JiscBody1> with the subject </JiscBody1>
      <JiscBody1>&quot;{EMAIL_SUBJECT_PREFIX}&quot;</JiscBody1>
    </div>
  );
};

/**
 * Display the search results.
 */
export const ResultsPage: React.FC = () => {
  return (
    <div>
      <br />
      <Link to="/">
        <JiscBody1>Back to search</JiscBody1>
      </Link>
      <ResultsContent />
      <Spacer size={4} />
      <ResultsPageContact />
    </div>
  );
};
