import React from "react";
import { AgreementSearch } from "../Forms/AgreementSearch/AgreementSearch";
import { InstitutionForm } from "../Forms/InstitutionForm/InstitutionForm";
import { JournalForm } from "../Forms/JournalForm/JournalForm";

export const SearchSection: React.FC = () => {
  return (
    <div>
      <InstitutionForm />
      <JournalForm />
      <AgreementSearch />
    </div>
  );
};
