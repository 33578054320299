import React, { useEffect, useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import styles from "./Accordion.module.css";

type Kind = "institution" | "publisher" | "journal";

const gridStyleMap: Record<Kind, string> = {
  institution: "",
  publisher: styles.publisherGridColumns,
  journal: styles.journalGridColumns,
};

export interface AccordionProps {
  headings: JSX.Element;
  onOpenFunc?: (open: boolean) => void;
  kind: Kind;
}

export const Accordion: React.FC<AccordionProps> = ({
  children,
  headings,
  onOpenFunc,
  kind,
}) => {
  const [open, setOpen] = useState(false);

  const openStyle = open ? styles.Open : "";

  const onClick = async () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (onOpenFunc) {
      onOpenFunc(open);
    }
  }, [onOpenFunc, open]);

  const handleKeyUp = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === "Space" || e.key === " ") {
      await onClick();
    }
    return null;
  };

  return (
    <li>
      <div className={styles.Accordion}>
        <div
          className={`${styles.AccordionHeadingContainer} ${gridStyleMap[kind]}`}
          onClick={() => onClick()}
          role="button"
          tabIndex={0}
          onKeyUp={e => handleKeyUp(e)}
        >
          {headings}
          <div className={`${styles.AccordionIcon} ${styles.Col5}`}>
            {open ? <RemoveIcon /> : <AddIcon />}
          </div>
        </div>

        {open && <div className={`${styles.AccordionBody} ${openStyle}`}>{children}</div>}
      </div>
    </li>
  );
};
