import React, { useContext, useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { Navigate } from "react-router";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import { StateContext } from "../../../state/StateContextProvider";
import { Spacer } from "../../Text/Spacer";
import styles from "./AgreementSearch.module.css";
import {
  constructGetAgreementsByPublicationsVariables,
  constructGetAgreementsVariables,
  GET_AGREEMENTS_BY_PUBLICATIONS_QUERY,
  GET_AGREEMENTS_QUERY,
} from "../../../network/gql/queries";
import {
  GetAgreementsByPublicationsResult,
  GetAgreementsResult,
} from "../../../network/gql/types";
import { ActionType } from "../../../utils/enums";
import {
  Agreement,
  IdToAgreementsMap,
  transformAgreement,
  transformAgreementsMap,
} from "../../../state/types";
import { JiscBody1 } from "../../Text/JiscBody1";

const ERROR_FETCHING_MSG = "Failed to fetch agreements";

export const AgreementSearch: React.FC = () => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(StateContext);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [error, setError] = useState("");
  const [navigateToResults, setNavigateToResults] = useState(false);

  useEffect(() => {
    setButtonEnabled(state.selectedInstitution !== null);
  }, [state]);

  /**
   * Search for TAs based on an institution
   */
  const getAgreementsByHEI = async (): Promise<Agreement[]> => {
    const response: ApolloQueryResult<GetAgreementsResult> =
      await client.query<GetAgreementsResult>({
        query: GET_AGREEMENTS_QUERY,
        variables: constructGetAgreementsVariables(state, 0),
      });

    return response.data.getAgreements.agreements.map(a => transformAgreement(a));
  };

  /**
   * Search for TA based on journals and (optionally) filtered by
   * an institution.
   */
  const getAgreementsByPublications = async (): Promise<IdToAgreementsMap> => {
    const response: ApolloQueryResult<GetAgreementsByPublicationsResult> =
      await client.query<GetAgreementsByPublicationsResult>({
        query: GET_AGREEMENTS_BY_PUBLICATIONS_QUERY,
        variables: constructGetAgreementsByPublicationsVariables(state, 0),
      });

    return transformAgreementsMap(
      response.data.getAgreementsByPublications.agreementsMap
    );
  };

  /**
   * Trigger a TA search based on the app state.
   */
  const onClick = async () => {
    // Clear relevant state
    dispatch({
      type: ActionType.SetAgreements,
      payload: [],
    });
    dispatch({
      type: ActionType.SetAgreementSearchPerformed,
      payload: false,
    });

    setLoading(true);
    setError("");

    let querySuccessful = false;

    if (state.selectedJournals.length === 0) {
      try {
        const agreements = await getAgreementsByHEI();
        dispatch({
          type: ActionType.SetAgreements,
          payload: agreements,
        });
        querySuccessful = true;
      } catch (err: any) {
        setError(ERROR_FETCHING_MSG);
      }
    } else {
      try {
        const agreementsMap = await getAgreementsByPublications();
        dispatch({
          type: ActionType.SetJournalAgreements,
          payload: agreementsMap,
        });
        querySuccessful = true;
      } catch (err: any) {
        setError(ERROR_FETCHING_MSG);
      }
    }

    setLoading(false);

    if (querySuccessful) {
      dispatch({
        type: ActionType.SetAgreementSearchPerformed,
        payload: true,
      });
      setNavigateToResults(true);
    }
  };

  if (navigateToResults) {
    return <Navigate to="/results" />;
  }

  return (
    <div>
      <Spacer size={2} />
      <div className={styles.Button}>
        <Button
          disabled={!buttonEnabled}
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          <div className="flex align-items-center">
            {loading ? (
              <div style={{ transform: "translate(0, 10%)" }}>
                <CircularProgress
                  size="1rem"
                  role="status"
                  aria-hidden="true"
                  color="inherit"
                  variant="indeterminate"
                />
              </div>
            ) : (
              "Search"
            )}
          </div>
        </Button>
        {error && (
          <div>
            <Spacer size={2} />
            <JiscBody1>{error}</JiscBody1>
          </div>
        )}
      </div>
    </div>
  );
};
