import React from "react";

import { Paper, Box } from "@material-ui/core";
import { JiscBody1 } from "../components/Text/JiscBody1";
import { Agreement } from "../state/types";

export const CONTACT_EMAIL = "help@jisc.ac.uk";
export const EMAIL_SUBJECT_PREFIX = "Sherpa TA Lookup";

/**
 * Agreement-specific contact information, suitable for adding to an accordion.
 *
 * @param agreement The Agreement that the user is contacting us about.
 */
export function contactSection(agreement: Agreement): JSX.Element {
  const subject = `${EMAIL_SUBJECT_PREFIX}: ${agreement.name} (${agreement.date_start} — ${agreement.date_end})`;

  const mailto = `mailto:${CONTACT_EMAIL}?subject=${encodeURIComponent(subject)}`;
  return (
    <Paper square style={{ maxHeight: 470, overflow: "auto" }}>
      <Box m={2} pt={0.5}>
        <JiscBody1 component="p">
          <a href={mailto}>Click here to email us about this record</a>
        </JiscBody1>
        <JiscBody1>Alternatively, email </JiscBody1>
        <JiscBody1 bold>{CONTACT_EMAIL}</JiscBody1>
        <JiscBody1> with the subject </JiscBody1>
        <JiscBody1>&quot;{subject}&quot;</JiscBody1>
      </Box>
    </Paper>
  );
}
