import React from "react";

import { Typography } from "@material-ui/core";

import styles from "./JiscH1.module.css";

interface Props {
  inline?: true;
}

export const JiscH1: React.FC<Props> = ({ inline, children }) => {
  let display: string;
  if (inline) {
    display = "inline-block";
  } else {
    display = "block";
  }
  return (
    <Typography style={{ display }} variant="h1" gutterBottom className={styles.root}>
      {children}
    </Typography>
  );
};
