import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { getEnvVar } from "../../utils/env";

export type GqlClient = ApolloClient<NormalizedCacheObject>;

let client: GqlClient;

/**
 * A custom fetch() implementation so we can GET the 'uri' that is
 * returned from the "/web" handler of graphql-api.
 */
async function customFetch(uri: string, options: RequestInit) {
  const queryResponse = await fetch(uri, options);
  const body = await queryResponse.json();
  if (!body.url) {
    throw new Error(`GQL query failed: ${JSON.stringify(body)}`);
  }
  const s3Response = await fetch(body.url);
  return s3Response;
}

function getProxyBaseUrl(): string {
  if (window.location.hostname === "localhost") {
    const stage = getEnvVar("REACT_APP_STAGE");
    return `http://localhost:3003/${stage}`;
  }

  return getEnvVar("REACT_APP_SEARCH_PROXY_ENDPOINT");
}

/**
 * Get the graphql-api GQL endpoint for this environment.
 */
function getSherpaSearchEndpoint(): string {
  return `${getProxyBaseUrl()}/proxy`;
}

/**
 * Create and cache the Apollo client, and return it.
 */
export function getApolloClient(): GqlClient {
  if (!client) {
    const uri = getSherpaSearchEndpoint();
    const link = createHttpLink({ uri, fetch: customFetch });
    client = new ApolloClient<NormalizedCacheObject>({
      cache: new InMemoryCache(),
      link,
    });
  }
  return client;
}
