import React from "react";
import { Typography, withStyles } from "@material-ui/core";

import { JiscH1 } from "../Text/JiscH1";
import { JiscBody1 } from "../Text/JiscBody1";

const BETA_ORANGE_HEX = "#ff8000";

const OrangeTextTypography = withStyles({
  root: {
    color: BETA_ORANGE_HEX,
  },
})(Typography);

export const Title: React.FC = () => {
  return (
    <>
      <div>
        <JiscH1 inline>Transitional Agreement Look-Up</JiscH1>{" "}
        <OrangeTextTypography
          style={{
            display: "inline-block",
            verticalAlign: "top",
            position: "relative",
            top: "25px",
          }}
          variant="subtitle1"
        >
          BETA
        </OrangeTextTypography>
      </div>
      <JiscBody1>
        <span>
          Welcome to the Transitional Agreement (TA) Look-Up tool - this service provides
          information on TAs held by institutions, and the journals listed within TAs.
          <br />
          <br />
          Please note that the terms of TAs apply to the corresponding author of the
          article only. Authors should always check with their institution prior to
          submission, as some agreements may have caps or other restrictions in place.
        </span>
      </JiscBody1>
    </>
  );
};
