import React from "react";

import styles from "./Spacer.module.css";

interface Props {
  size?: 1 | 2 | 3 | 4 | 5; // if you need more space, then this component is not appropriate for you. Use CSS.
}

export const Spacer: React.FC<Props> = ({ size = 1 }) => {
  return (
    <div>
      {[...Array(size)].map((value: undefined, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={styles.spacer} />
      ))}
    </div>
  );
};
