export interface Journal {
  id: string;
  title: string;
  issn?: string;
  eissn?: string;
  label: string;
  value: string;
}

export interface Publisher {
  name: string;
  joid: string;
  label: string;
  value: string;
}

export interface Institution {
  name: string;
  joid: string;
  label: string;
  value: string;
}

export interface Agreement {
  id: string;
  name: string;
  institution_name: string;
  institution_joid: string;
  publisher_name: string;
  publisher_joid: string;
  date_start: string;
  date_end: string;
  subscription_status: string;
  product_id: string;
  url: string;
}

export type IdToAgreementsMap = Record<string, Agreement[]>;

function cleanInstitutionName(name: string): string {
  return name.replace("(consortium account)", "");
}

/**
 * Convert a date in YEAR-MONTH-DAY format to DAY-MONTH-YEAR format.
 */
function reformatDate(date: string): string {
  return date.split("-").reverse().join("-");
}

/**
 * Return a copy of an Agreement without all the JSON fields we don't want,
 * and with the extra ones we need, transforming any internal fields as
 * necessary for the frontend.
 *
 * nb. The journals list is populated by a subsequent API call.
 */
export function transformAgreement(agreement: Agreement): Agreement {
  return {
    id: agreement.id,
    name: agreement.name,
    institution_name: cleanInstitutionName(agreement.institution_name),
    institution_joid: agreement.institution_joid,
    publisher_name: agreement.publisher_name,
    publisher_joid: agreement.publisher_joid,
    date_start: reformatDate(agreement.date_start),
    date_end: reformatDate(agreement.date_end),
    subscription_status: agreement.subscription_status,
    product_id: agreement.product_id,
    url: `https://subscriptionsmanager.jisc.ac.uk/catalogue/${agreement.product_id}`,
  };
}

/**
 * Return a copy of an AgreementsMap with the agreements run through the
 * transformer function.
 */
export function transformAgreementsMap(agMap: IdToAgreementsMap): IdToAgreementsMap {
  const mapCopy: IdToAgreementsMap = {};

  Object.keys(agMap).forEach(publicationId => {
    mapCopy[publicationId] = agMap[publicationId].map(agreement => {
      return transformAgreement(agreement);
    });
  });

  return mapCopy;
}

/**
 * Return a string summarising the ISSN and / or EISSN of a journal.
 *
 * e.g. if the issn is 1122-3344 and the eissn is 5566-7788, the
 * return value will be: '1122-3344, 5566-7788'
 */
export function getJournalIdentifiers(journal: Journal): string {
  // Use a set because sometimes the issn and eissn are the same.
  const identifiersSet = new Set<string>();

  if (journal.issn) {
    identifiersSet.add(journal.issn);
  }
  if (journal.eissn) {
    identifiersSet.add(journal.eissn);
  }

  const identifiers = Array.from(identifiersSet);
  return identifiers.length > 0 ? `${identifiers.join(", ")}` : "";
}

export function transformJournal(journal: Journal): Journal {
  const identifiersLabel = getJournalIdentifiers(journal);

  return {
    id: journal.id,
    title: journal.title,
    issn: journal.issn,
    eissn: journal.eissn,
    label: `${journal.title} (${identifiersLabel})`,
    value: journal.id,
  };
}
