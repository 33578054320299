import React, { useContext, useState } from "react";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import { LoadOptions, Response } from "react-select-async-paginate";
import { StateContext } from "../../../state/StateContextProvider";

import { JiscH2 } from "../../Text/JiscH2";
import { Spacer } from "../../Text/Spacer";

import { ListItem } from "../../ListItem/ListItem";
import { Journal, transformJournal } from "../../../state/types";
import { ActionType } from "../../../utils/enums";

import styles from "../Form.module.css";
import { QueryRunnerSelector } from "../QueryRunnerSelector/QueryRunnerSelector";
import { SEARCH_KBP_PUBLICATIONS_QUERY } from "../../../network/gql/queries";
import { SearchPublicationsResult } from "../../../network/gql/types";

const QUERY_SIZE_ARG = 100;

/**
 * Search for one or more Journals.
 */
export const JournalForm: React.FC = () => {
  const client = useApolloClient();

  const { state, dispatch } = useContext(StateContext);

  const selectedJournals = state.selectedJournals;
  const [error, setError] = useState("");

  function deleteJournal(id: string) {
    const newList = selectedJournals.filter((j: Journal) => j.value !== id);
    dispatch({ type: ActionType.SetJournals, payload: newList });
    dispatch({ type: ActionType.SetAgreementSearchPerformed, payload: false });
  }

  const loadOptions: LoadOptions<Journal, unknown> = async (
    searchQuery: string
  ): Promise<Response<Journal, unknown>> => {
    let response: ApolloQueryResult<SearchPublicationsResult>;
    const query = searchQuery.trim();

    try {
      response = await client.query({
        query: SEARCH_KBP_PUBLICATIONS_QUERY,
        variables: { query, size: QUERY_SIZE_ARG },
      });
    } catch (err: any) {
      setError(err.message);
      return {
        options: [],
        hasMore: false,
      };
    }
    const results: readonly Journal[] =
      response.data.searchKbpPublications.publications.map((pub: any) =>
        transformJournal(pub)
      );

    return {
      options: results,
      hasMore: false,
    };
  };

  const addJournal = (selection: Journal) => {
    const existingJournals = state.selectedJournals.map((j: Journal) => j.id);
    if (!existingJournals.includes(selection.id)) {
      dispatch({
        type: ActionType.SetJournals,
        payload: [...state.selectedJournals, selection],
      });
      dispatch({ type: ActionType.SetAgreementSearchPerformed, payload: false });
    }
  };

  return (
    <div>
      <JiscH2>Select your journals (optional)</JiscH2>
      <Spacer size={2} />
      <div className={styles.Form}>
        <QueryRunnerSelector<Journal>
          id="journal-select-form"
          placeholder="Enter journal name, ISSN, or eISSN"
          loadOptionsFunc={loadOptions}
          saveSelectionFunc={addJournal}
          enabled
          openMenuOnClick={false}
          error={error}
        />
      </div>
      <ul className={styles.ListContainer}>
        {selectedJournals.map((journal: Journal) => {
          return <ListItem key={journal.id} item={journal} deleteItem={deleteJournal} />;
        })}
      </ul>
    </div>
  );
};
